import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { LinkCoverDocument } from "../components/elements"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { BlackSection } from "../components/structure"
import AUP from "../doc/important-documents/ACCEPTABLE USE POLICY .pdf"

const PDFLinks = [
  {
    id: 1,
    name: "ACCEPTABLE USE POLICY.pdf",
    page: "/AcceptableUsePolicy",
  },
  {
    id: 2,
    name: "Appointment Of Advocate.pdf",
    page: "/AppointmentOfAdvocate",
  },
  {
    id: 3,
    name: "COMPLAINTS HANDLING POLICY.pdf",
    page: "/complaints-handling-policy",
  },
  {
    id: 4,
    name: "Direct Debit Request Service Agreement.pdf",
    page: "/direct-debit-request-service-agreement",
  },
  {
    id: 5,
    name: "Direct Debit.pdf",
    page: "/direct-debit",
  },
  {
    id: 6,
    name: "DISCLOSURE PAGE.pdf",
    page: "/disclosure-page",
  },
  {
    id: 7,
    name: "Fair Use Policy.pdf",
    page: "/fair-use-policy",
  },
  {
    id: 8,
    name: "FINANCIAL HARDSHIP POLICY.pdf",
    page: "/financial-hardship-policy",
  },
  {
    id: 9,
    name: "Key Facts Sheet - NBN Services.pdf",
    page: "/key-fact-sheet-nbn-services",
  },
  {
    id: 10,
    name: "Privacy Policy.pdf",
    page: "/privacy-policy",
  },
  {
    id: 11,
    name: "Return Policy.pdf",
    page: "/return-policy",
  },
  {
    id: 12,
    name: "Standard Form Of Agreement.pdf",
    page: "/standard-form-of-agreement",
  },
  {
    id: 13,
    name: "Website Disclaimer.pdf",
    page: "/website-disclaimer",
  },
  {
    id: 14,
    name: "Website Terms And Conditions Of Use Of Services.pdf",
    page: "/website-term-and-conditions-of-use-of-servoices",
  },
]
export default function ImportantDocuments() {
  return (
    <Layout>
      <SEO title="Important Documents" />
      <BlackSection padDoc title="Important Documents" />
      <LinkCoverDocument>
        <Note>
          Important information: If you find difficulty accesing in smaller
          device screen than please open in Computer or Bigger screen devices.
        </Note>
        <Cover>
          {PDFLinks.map((items, id) => {
            return (
              <PdfNavLink to={items.page} target="_blank" alt={items.page}>
                {items.id} {items.name}
              </PdfNavLink>
            )
          })}
        </Cover>
      </LinkCoverDocument>
    </Layout>
  )
}

const PdfNavLink = styled(Link)`
  font-size: 2rem;
  color: ${props => props.theme.color.black};
  font-weight: 600;
  letter-spacing: 0.05em;
`

const Note = styled.p`
  color: brown;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 150%;
`

const Cover = styled.div`
  padding: 3rem 1rem;
  display: inline-flex;
  flex-direction: column;
`
